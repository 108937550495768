import { useStaticQuery, graphql } from 'gatsby';
export const useDesignSupportPageMetaDataCs = () => {
  const site = useStaticQuery(graphql`
    query DesignSupportPage {
      wpPage(id: { eq: "cG9zdDoyMTQz" }) {
        id
        title
        content
        modified(locale: "")
      }
    }
  `);
  return site?.wpPage;
};
